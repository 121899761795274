
import { useCheckout } from '@/composables/checkout/useCheckout'
import { useNumeral } from '@/composables/useNumeral'
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    const { format } = useNumeral()

    const {
      isLoaded,
      total,
      currencyCode
    } = useCheckout()

    return {
      isLoaded,
      total,
      currencyCode,
      format
    }
  }
})
