<template>
  <div v-if="isLoaded">
    <div v-if="!discount.hasDiscount">
      <label for="">Adicionar código promocional</label>
      <div class="flex items-center justify-items-center">
        <base-input
          type="text"
          v-model="coupon"
          class-name="rounded-r-none"
        />
        <button
          class="btn btn-secondary rounded-tl-none rounded-bl-none whitespace-nowrap"
          type="button"
          :disabled="coupon === ''"
          @click="researchCoupon"
        >
          {{ $t('action.apply') }}
          <i class="fas fa-spinner fa-spin !mr-0 ml-4" v-if="isResearchCoupon"></i> 
        </button>
      </div>
      <small class="text-red-800" v-if="couponErrorMessage">{{ couponErrorMessage }}</small>
    </div>
    <div v-if="discount.hasDiscount">
      <div class="flex items-center justify-between">
        <div class="bg-gray-200 p-2 rounded-md">
          <i class="fas fa-tag px-2 opacity-50"></i>
          <span class="font-semibold">{{ coupon }}</span>
          <button @click.prevent="resetCoupon" class="focus:outline-none px-2">
            <i class="fas fa-times opacity-50"></i>
          </button>
        </div>
        <div>-{{format(discount.difference, '$0.00', currencyCode)}}</div>
      </div>
      <small class="text-gray-500">
        <template v-if="discount.discountType === 'percentage'">
          {{format(discount.grossValue / 100, '0%')}} de desconto
        </template>
        <template v-if="discount.discountType === 'flat'">
          {{format(discount.grossValue, '$0.00', currencyCode)}} de desconto
        </template>
      </small>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useCheckout } from '@/composables/checkout/useCheckout'
import BaseInput from '@/components/base/BaseInput.vue'
import { useNumeral } from '@/composables/useNumeral'

export default defineComponent({
  components: {
    BaseInput,
  },

  setup () {
    const {
      isLoaded,
      checkoutFormData,
      updateCheckoutFormData,
      researchCoupon,
      isResearchCoupon,
      discount,
      resetCoupon,
      couponErrorMessage,
      currencyCode
    } = useCheckout()

    const { format } = useNumeral()

    const coupon = computed({
      get: () => checkoutFormData.value.coupon || '',
      set: (value) => updateCheckoutFormData({coupon: value})
    })

    return {
      isLoaded,
      coupon,
      isResearchCoupon,
      researchCoupon,
      discount,
      format,
      resetCoupon,
      couponErrorMessage,
      currencyCode
    }
  }
})
</script>
