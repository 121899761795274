<template>
  <div v-if="isLoaded && installmentsOptions.length">
    <label for="">Parcelamento</label>
    <multi-select
      v-model="installments"
      :options="installmentsOptions"
      :can-clear="false"
      mode="single"
    />
    <small class="text-gray-500">Sem juros</small>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useCheckout } from '@/composables/checkout/useCheckout'
import MultiSelect from '@vueform/multiselect'

export default defineComponent({
  components: {
    MultiSelect,
  },

  setup () {
    const {
      isLoaded,
      installments: installmentsOptions,
      checkoutFormData,
      updateCheckoutFormData
    } = useCheckout()

    const installments = computed({
      get: () => checkoutFormData.value.installments,
      set: (value) => updateCheckoutFormData({installments: value})
    })

    return {
      isLoaded,
      installments,
      installmentsOptions
    }
  }
})
</script>
