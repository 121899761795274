
import { computed, defineComponent } from 'vue'
import { useCheckout } from '@/composables/checkout/useCheckout'
import BaseInput from '@/components/base/BaseInput.vue'
import { useNumeral } from '@/composables/useNumeral'

export default defineComponent({
  components: {
    BaseInput,
  },

  setup () {
    const {
      isLoaded,
      checkoutFormData,
      updateCheckoutFormData,
      researchCoupon,
      isResearchCoupon,
      discount,
      resetCoupon,
      couponErrorMessage,
      currencyCode
    } = useCheckout()

    const { format } = useNumeral()

    const coupon = computed({
      get: () => checkoutFormData.value.coupon || '',
      set: (value) => updateCheckoutFormData({coupon: value})
    })

    return {
      isLoaded,
      coupon,
      isResearchCoupon,
      researchCoupon,
      discount,
      format,
      resetCoupon,
      couponErrorMessage,
      currencyCode
    }
  }
})
