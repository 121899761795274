<template>
  <div
    class="flex justify-between items-center font-semibold"
    v-if="isLoaded"
  >
    <div>TOTAL</div>
    <div class="whitespace-nowrap">{{format(total, '$0.00', currencyCode)}}</div>
  </div>
</template>

<script lang="ts">
import { useCheckout } from '@/composables/checkout/useCheckout'
import { useNumeral } from '@/composables/useNumeral'
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    const { format } = useNumeral()

    const {
      isLoaded,
      total,
      currencyCode
    } = useCheckout()

    return {
      isLoaded,
      total,
      currencyCode,
      format
    }
  }
})
</script>